import useLocalCache from 'hooks/useLocalCache';
import React, { useCallback } from 'react';
import { APICacheContext } from './APICacheContext';

export const APICacheProvider: React.FC = ({ children }) => {
  const [getState, setState, removeState] = useLocalCache<{
    [key: string]: any;
  }>('api-cache');

  const getCacheForAPI = (endPoint: string) => {
    const state = getState();

    if (!state) {
      return null;
    }

    return state[endPoint];
  };

  const updateCacheForAPI = (endPoint: string, payload: any) => {
    const state = getState();
    setState({
      ...state,
      [endPoint]: payload,
    });
  };

  return (
    <APICacheContext.Provider
      value={{
        updateCacheForAPI,
        getCacheForAPI,
        invalidateCache: removeState,
      }}
    >
      {children}
    </APICacheContext.Provider>
  );
};
