function useLocalCache<T>(
  key: string
): [() => T | null, (payload: T | null) => void, () => void] {
  const setState = (payload: T | null) => {
    localStorage.setItem(key, JSON.stringify(payload));
  };

  const getState = () => {
    const stringifiedCache = localStorage.getItem(key);

    const localCache = stringifiedCache
      ? (JSON.parse(stringifiedCache) as T)
      : null;

    return localCache;
  };

  const removeState = () => localStorage.removeItem(key);

  return [getState, setState, removeState];
}

export default useLocalCache;
