import HtmlWrapper from 'components/HtmlWrapper';
// import { useFormContext } from 'context/FormDrawerContext/FormDrawerContext';
import { useEffect } from 'react';
import { ModuleInfoType } from 'types/ProductDetailsType';
import { useHistory, useRouteMatch } from 'react-router-dom';

type Props = {
  formData: ModuleInfoType;
};

export interface FormMatchParams {
  id: string;
  stepId: string;
}

const FormStartPage = (props: Props) => {
  // const route = useHistory();
  const { params } = useRouteMatch<FormMatchParams>();
  // const { id } = params;

  useEffect(() => {
    // setCompletionScreen(true);
    // localStorage.removeItem('brij-form');
  }, []);

  //   const onFormStart = () => {
  //     route.push(`/c/${id}/form/step/1`);
  //   };

  return (
    <HtmlWrapper
      width='100%'
      direction='column'
      dangerouslySetInnerHTML={{ __html: props.formData.content }}
    />
  );
};

export default FormStartPage;
