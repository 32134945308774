import { RegistrationType } from './getRegisterText';

const getSuccessTitle = (
  registrationType: string = RegistrationType.REGISTER
): string => {
  switch (registrationType) {
    case RegistrationType.REGISTER:
      return 'Product Registered!';
    case RegistrationType.SIGNUP:
      return "You're Signed Up";
    case RegistrationType.ACTIVATE:
      return 'Warranty Activated!';
  }

  return 'Product Registered!';
};

export default getSuccessTitle;
